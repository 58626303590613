


import Button from '../Button';
import Email from '../Email';
import FirstName from '../FirstName';
import LastName from '../LastName';
import Notice from '../Notice';
const FormThree = ({ child, classAddDisable, elements, fName, lName, email, placeholder, setFName, setLName, setEmail, ValidateEmail, content, handleFormData, emailValid, loading, showNotice, message }) => {
    return <div className="form">
        {child}
        <div className="nameElement">
            <FirstName elements={elements} fName={fName} placeholder={placeholder} setFName={setFName} />
            <LastName elements={elements} lName={lName} placeholder={placeholder} setLName={setLName} />
        </div>

        <div className="btnElement">
            <Email email={email} placeholder={placeholder} setEmail={setEmail} ValidateEmail={ValidateEmail} />
            <Button classAddDisable={classAddDisable} content={content} handleFormData={handleFormData} emailValid={emailValid} loading={loading} />
        </div>

        <Notice showNotice={showNotice} message={message} />
    </div>
}
export default FormThree;