
import icons from '../../utils/icons';
import Button from '../Button';
import Email from '../Email';
import Notice from '../Notice';

const FormTwo = ({ attributes, child, classAddDisable, elements, email, placeholder, setEmail, ValidateEmail, content, handleFormData, emailValid, loading, showNotice, message }) => {
    const { icon } = attributes;
    return <div className="form">
        {elements?.icon &&
            <div className="iconElement">
                <div className="icon">
                    {icons?.openGmail(icon?.color)}
                </div>
            </div>
        }
        {child}
        <div className="emailBtnElement">
            <Email email={email} placeholder={placeholder} setEmail={setEmail} ValidateEmail={ValidateEmail} />
            <Button classAddDisable={classAddDisable} content={content} handleFormData={handleFormData} emailValid={emailValid} loading={loading} />
        </div>

        <Notice showNotice={showNotice} message={message} />

    </div>
}
export default FormTwo;