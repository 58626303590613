

import { getBoxValue } from './utils/functions';

import { getBackgroundCSS, getBorderCSS, getColorsCSS, getTypoCSS } from '../../Components/utils/getCSS';
const Style = ({ attributes, clientId }) => {
	const { iconBG, icon, titleTypo, titleColor, descTypo, descColor, formBG, formPadding, formBorder, fieldTypo, fieldColors, fieldPadding, fieldBorder, fieldMargin, btnTypo, btnColors, btnHovColors, btnPadding, btnBorder, messageColors, messageTypo, themeWidth, themeAlign, popupBtn, form4LeftImage, brandLogo, inputLabel, closeBtn, titleMargin, descMargin, btnMargin, modal, imgArea } = attributes;

	const { colors, size } = closeBtn;
	const iconSize = Math.ceil(parseInt(size) / 1.4);

	const formElement = `#mcbMailChimp-${clientId} .mcbMailChimp .form`;
	const popupBtnEle = `#mcbMailChimp-${clientId} .mcbMailChimp`;

	return <style dangerouslySetInnerHTML={{
		__html: `

		${getTypoCSS('', popupBtn?.typo)?.googleFontLink}
		${getTypoCSS('', inputLabel?.typo)?.googleFontLink}
		${getTypoCSS('', titleTypo)?.googleFontLink}
		${getTypoCSS('', descTypo)?.googleFontLink}
		${getTypoCSS('', fieldTypo)?.googleFontLink}
		${getTypoCSS('', btnTypo)?.googleFontLink}
		${getTypoCSS('', messageTypo)?.googleFontLink}
		${getTypoCSS(`${popupBtnEle}.form8 .form .bottom .content .label`, inputLabel?.typo)?.styles}
		${getTypoCSS(`${popupBtnEle} .popupBtn`, popupBtn?.typo)?.styles}
		${getTypoCSS(`${formElement} h2`, titleTypo)?.styles}
		${getTypoCSS(`${formElement} p`, descTypo)?.styles}
		${getTypoCSS(`${formElement} input::placeholder`, fieldTypo)?.styles}
		${getTypoCSS(`${formElement} input`, fieldTypo)?.styles}
		${getTypoCSS(`${formElement} .submitBtn button`, btnTypo)?.styles}
		${getTypoCSS(`${formElement} .noticeElement > p`, messageTypo)?.styles}


		${popupBtnEle} .popupBtn {
			${getColorsCSS(popupBtn?.colors)};
			${getBorderCSS(popupBtn?.border)};
			padding: ${getBoxValue(popupBtn?.padding)};
		}

		${popupBtnEle} .popupBtn:hover {
			${getColorsCSS(popupBtn?.hoverColors)};
		}

		#mcbMailChimp-${clientId} {
			justify-content:${themeAlign?.desktop}; 
		}

		#mcbMailChimp-${clientId} .mcbMailChimp{
			width: ${themeWidth?.desktop};
		}

		@media (max-width: 768px) { 
			#mcbMailChimp-${clientId}{
				justify-content:${themeAlign?.tablet};
			}
		}

		@media (max-width: 768px) {
			#mcbMailChimp-${clientId} .mcbMailChimp{
				width:${themeWidth?.tablet};
			}
		}

		@media (max-width: 576px) {
			#mcbMailChimp-${clientId}{
				justify-content:${themeAlign?.mobile};
			}
		}

		@media (max-width: 576px) {
			#mcbMailChimp-${clientId} .mcbMailChimp{
				width:${themeWidth?.mobile};
			}
		}	

		#mcbMailChimp-${clientId} .form1 .iconElement .icon {
			${getBackgroundCSS(iconBG)};
		}

		#mcbMailChimp-${clientId} .form1 .iconElement .icon::after {
			border-color:${icon?.color};
		}

		${popupBtnEle}.default .form, ${popupBtnEle}.form1 .form, ${popupBtnEle}.form2 .form, ${popupBtnEle}.form3 .form, ${popupBtnEle}.form7 .form{
			${getBackgroundCSS(formBG)};
			padding: ${getBoxValue(formPadding)};
			${getBorderCSS(formBorder)};
		}

		${popupBtnEle}.form4 .form, ${popupBtnEle}.form5 .form, ${popupBtnEle}.form6 .form, ${popupBtnEle}.form8 .form {
			${getBackgroundCSS(formBG)};
			${getBorderCSS(formBorder)};
		}

		${popupBtnEle}.form8 .form .top .right .logo {
			width: ${brandLogo?.size}px;
			height: ${brandLogo?.size}px;
		}

		${popupBtnEle}.form8 .form .bottom .content .label {
			color:${inputLabel?.color};
		}

		${popupBtnEle}.form4 .form .right, ${popupBtnEle}.form5 .form .bottom, ${popupBtnEle}.form6 .form .left, ${popupBtnEle}.form8 .form .bottom{
			padding: ${getBoxValue(formPadding)};
		}

		${popupBtnEle}.form4 .form .left, ${popupBtnEle}.form5 .form .top, ${popupBtnEle}.form6 .form .right,${popupBtnEle}.form8 .form .top {
			${getBackgroundCSS(form4LeftImage)};
		}
		 
		${formElement} .noticeElement .success{
			color:${messageColors?.success};
		}

		${formElement} .noticeElement .error{
			color:${messageColors?.error};
		}

		${formElement} h2{
			color:${titleColor};
			margin:${getBoxValue(titleMargin)};
		}

		${formElement} p{
			color:${descColor};
			margin:${getBoxValue(descMargin)};
		}

		${formElement} .noticeElement p {
			margin-top: 15px;
		}

		${formElement} input::placeholder {
			${getColorsCSS(fieldColors)};
		}

		${formElement} input[type="text"], ${formElement} input[type="email"]{
			${getColorsCSS(fieldColors)};
			padding:${getBoxValue(fieldPadding)};
			${getBorderCSS(fieldBorder)};
			margin:${getBoxValue(fieldMargin)};
			
		}

		${formElement} .submitBtn button {
			${getColorsCSS(btnColors)};
			padding:${getBoxValue(btnPadding)};
			${getBorderCSS(btnBorder)};
		}

		${formElement} .submitBtn {
			margin:${getBoxValue(btnMargin)};
		}

		${formElement} .submitBtn button:hover {
			${getColorsCSS(btnHovColors)};
			opacity:0.9;
		}

		#mcbMailChimp-${clientId} .form2 .form .submitBtn button {
			border-top-left-radius: 0px;
			border-bottom-left-radius: 0px;
		}

		@media all and (max-width: 575px) {
			#mcbMailChimp-${clientId} .form2 .form .submitBtn button {
				${getBorderCSS(btnBorder)};
			}
		 }

		${formElement} .submitBtn .loader{
			${getBorderCSS(btnBorder)};
		}

		${formElement} .submitBtn .disable {
			opacity:0.7;
			pointer-events: none;
		}

		${popupBtnEle} .modalArea .allForms .closeIcon {
			${getColorsCSS(colors)};
			width:${size}px;
			height:${size}px;
		}

		${popupBtnEle} .modalArea .allForms .closeIcon svg{
			width: ${iconSize}px;
			height: ${iconSize}px;
		}

		${popupBtnEle} .modalArea.modalAreaNatural .allForms, ${popupBtnEle} .modalArea.modalAreaFBottom .allForms {
			width:${modal?.width?.desktop};
		}

		${popupBtnEle}.form5 .form .top{
			height:${imgArea?.height?.desktop};
		}

		@media (max-width: 768px) {
			${popupBtnEle} .modalArea.modalAreaNatural .allForms, ${popupBtnEle} .modalArea.modalAreaFBottom .allForms {
				width:${modal?.width?.tablet};
			}

			${popupBtnEle}.form5 .form .top{
				height:${imgArea?.height?.tablet};
			}
		}

		${popupBtnEle} .popupBtnArea {
			justify-content:${popupBtn?.align}
		}

		@media (max-width: 576px) {
			${popupBtnEle} .modalArea.modalAreaNatural .allForms, ${popupBtnEle} .modalArea.modalAreaFBottom .allForms {
				width:${modal?.width?.mobile};
			}

			${popupBtnEle}.form5 .form .top{
				height:${imgArea?.height?.mobile};
			}
		}

		
		`.replace(/\s+/g, ' ')
	}} />;
}
export default Style;