import Button from '../Button';
import Email from '../Email';
import Notice from '../Notice';

const FormSeven = ({ child, classAddDisable, placeholder, email, setEmail, ValidateEmail, content, handleFormData, emailValid, loading, showNotice, message }) => {

    return <div className="form">
        {child}
        <Email email={email} setEmail={setEmail} placeholder={placeholder} ValidateEmail={ValidateEmail} />
        <Button classAddDisable={classAddDisable} content={content} handleFormData={handleFormData} emailValid={emailValid} loading={loading} />
        <Notice showNotice={showNotice} message={message} />
    </div>
}
export default FormSeven;