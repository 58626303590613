import Button from '../Button';
import Email from '../Email';
import FirstName from '../FirstName';
import LastName from '../LastName';
import Notice from '../Notice';

const FormFive = ({ child, classAddDisable, elements, fName, lName, email, placeholder, setFName, setLName, setEmail, ValidateEmail, content, handleFormData, emailValid, loading, showNotice, message }) => {
    const commonProps = { elements, placeholder };
    return <div className="form">
        <div className="top">
        </div>
        <div className="bottom">
            {child}
            <FirstName {...commonProps} fName={fName} setFName={setFName} placeholder={placeholder} />
            <LastName {...commonProps} lName={lName} setLName={setLName} placeholder={placeholder} />
            <Email email={email} setEmail={setEmail} placeholder={placeholder} ValidateEmail={ValidateEmail} />
            <Button classAddDisable={classAddDisable} content={content} handleFormData={handleFormData} emailValid={emailValid} loading={loading} />

            <Notice showNotice={showNotice} message={message} />
        </div>

    </div>
}
export default FormFive;