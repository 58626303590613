import FormFive from './Forms/FormFive';
import FormFour from './Forms/FormFour';
import FormThree from './Forms/FormThree';
import FormTwo from './Forms/FormTwo';
import FormOne from './Forms/FormOne';
import Default from './Forms/Default';
import FormSix from './Forms/FormSix';
import FormSeven from './Forms/FormSeven';
import FormEight from './Forms/FormEight';

const templates = {
    default: Default,
    form1: FormOne,
    form2: FormTwo,
    form3: FormThree,
    form4: FormFour,
    form5: FormFive,
    form6: FormSix,
    form7: FormSeven,
    form8: FormEight,
    form9: FormFour,
    form10: FormSix
}

const AllForms = ({ theme, globalProps, attributes }) => {
    const { layout } = attributes;
    const { placement } = layout;

    const Form = placement === 'floating-top' ? templates['form7'] : templates[theme] ?? null;
    return <>
        {Form ? <Form {...globalProps} attributes={attributes} /> : <h2>No Theme Selected</h2>}

        {/* {'default' === theme &&
            <Default {...globalProps} />}

        {'form1' === theme &&
            <FormOne {...globalProps} attributes={attributes} />}

        {'form2' === theme &&
            <FormTwo {...globalProps} attributes={attributes} />}

        {'form3' === theme &&
            <FormThree {...globalProps} />} */}
    </>
}
export default AllForms;