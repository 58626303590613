const Email = ({ email, placeholder, setEmail, ValidateEmail }) => {
  return (
    <>
      <input
        value={email}
        type="email"
        id="email"
        name="email"
        placeholder={placeholder?.email}
        onChange={(e) => {
          setEmail(e.target.value);
          ValidateEmail(e.target.value);
        }}
      />
    </>
  );
};
export default Email;
