import Button from '../Button';
import Email from '../Email';
import Notice from '../Notice';

const FormEight = ({ child, classAddDisable, email, placeholder, setEmail, ValidateEmail, content, handleFormData, emailValid, loading, showNotice, message, attributes }) => {

    const { brandLogo, inputLabel } = attributes;
    const { img } = brandLogo;



    return <div className="form">
        <div className="top">
            <div className="left">
                {child}
            </div>
            <div className="right">
                <div className="logo">
                    <img src={img?.url} alt={img?.alt} />
                </div>
            </div>
        </div>
        <div className="bottom">
            <div className="content">
                {inputLabel?.label && <div className="label">
                    Email
                </div>}
                <Email email={email} setEmail={setEmail} placeholder={placeholder} ValidateEmail={ValidateEmail} />
            </div>
            <Button classAddDisable={classAddDisable} content={content} handleFormData={handleFormData} emailValid={emailValid} loading={loading} />

            <Notice showNotice={showNotice} message={message} />
        </div>
    </div>
}
export default FormEight;