
export const getBoxValue = object => Object.values(object).join(' ');

export const generateString = (length) => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result.trim();
}

export const tabController = () => {
    setTimeout(() => {
        const panelBodies = document.querySelectorAll('.components-panel__body-title button');
        panelBodies.forEach(item => {
            item.addEventListener('click', clickEveryItem);
        });

        function clickEveryItem() {
            this.removeEventListener('click', clickEveryItem);
            panelBodies.forEach(item => {
                if (item.getAttribute('aria-expanded') === 'true' && !item.isEqualNode(this)) {
                    item.click();
                }
            });
            setTimeout(() => {
                this.addEventListener('click', clickEveryItem);
            }, 500);
        }
    }, 500);
};

const defaultTheme = {
    titleColor: '#000',
    descColor: '#000',
    formBG: { color: '#efefef' },
    formPadding: { top: '20px', right: '20px', bottom: '20px', left: '20px' },
    icon: { color: "#fff" },
    fieldColors: { color: '#a3a3a3', bg: '#f5f7fa' },
    fieldPadding: { top: '2px', right: '13px', bottom: '2px', left: '13px' },
    fieldBorder: { radius: '5px' },
    fieldMargin: { top: '0px', right: '0px', bottom: '10px', left: '0px' },
    btnColors: { color: "#fff", bg: "#2355f2" },
    btnHovColors: { color: "#fff", bg: "#0575e67d" },
    btnPadding: { top: '8px', right: '20px', bottom: '8px', left: '20px' },
    btnBorder: { radius: '5px' },
    btnMargin: { top: '0px', right: '0px', bottom: '0px', left: '0px' },
    titleMargin: { top: '0px', right: '0px', bottom: '15px', left: '0px' },
    descMargin: { top: '0px', right: '0px', bottom: '10px', left: '0px' }
}

export const checkTheme = (val) => {
    if (val === 'default') {
        return defaultTheme;
    }

    if (val === 'form1') {
        return {
            ...defaultTheme,
            titleColor: '#0575e6',
            descColor: '#fff',
            formBG: { color: '#2a2a2ae8' },
            formPadding: { top: '70px', right: '50px', bottom: '70px', left: '50px' },
            fieldColors: { bg: '#fff' },
            fieldBorder: { radius: '5px' },
            fieldMargin: { top: '0px', right: '0px', bottom: '0', left: '0px' },
            btnBorder: { radius: '5px' }
        }
    }

    if (val === 'form2') {
        return {
            ...defaultTheme,
            formPadding: { top: '50px', right: '50px', bottom: '70px', left: '50px' },
            icon: { color: "#000" },
            fieldPadding: { top: '0px', right: '0px', bottom: '0px', left: '10px' },
            fieldBorder: { radius: '5px' },
            btnPadding: { top: '8px', right: '20px', bottom: '8px', left: '20px' },
            fieldMargin: { top: '0px', right: '0px', bottom: '0px', left: '0px' },
            btnBorder: { radius: '5px' },

        }
    }

    if (val === 'form4' || val === 'form6' || val === 'form8') {
        return {
            ...defaultTheme,
            titleColor: '#fff',
            descColor: '#fff',
        }
    }

    if (val === 'form3' || val === 'form5' || val === 'form9' || val === 'form10') {
        return defaultTheme
    }

    if (val === 'form7') {
        return {
            ...defaultTheme,
            btnPadding: { top: '8px', right: '20px', bottom: '8px', left: '20px' },
        }
    }
}


export const checkLayout = (layout, theme) => {

    if ((((layout === "modal") || (layout === "auto-popup")) && theme === "form10") || (((layout === "modal") || (layout === "auto-popup")) && theme === "form9") || ((((layout === "modal") || (layout === "auto-popup"))) && theme === "form6") || (((layout === "modal") || (layout === "auto-popup")) && theme === "form4")) {
        return {
            modal: { width: { desktop: '600px', tablet: '500px', mobile: '400px' } }
        }
    }

    if ((layout === "floating-bottom" && theme === "form10") || (layout === "floating-bottom" && theme === "form9") || (layout === "floating-bottom" && theme === "form6") || (layout === "floating-bottom" && theme === "form4")) {
        return {
            modal: { width: { desktop: '500px', tablet: '400px', mobile: '350px' } }
        }
    }

    if (((layout === "modal") || (layout === 'auto-popup')) && theme === "form8") {
        return {
            modal: { width: { desktop: '500px', tablet: '400px', mobile: '350px' } }
        }
    }

    if (layout === "floating-bottom" && theme === "form8") {
        return {
            modal: { width: { desktop: '450px', tablet: '400px', mobile: '350px' } }
        }
    }

    if ((((layout === "modal") || (layout === "auto-popup")) && theme === "form5") || (((layout === "modal") || (layout === "auto-popup")) && theme === "form3") || (((layout === "modal") || (layout === "auto-popup")) && theme === "form2") || (((layout === "modal") || (layout === "auto-popup")) && theme === "form2") || (((layout === "modal") || (layout === "auto-popup")) && theme === "form1") || (((layout === "modal") || (layout === "auto-popup")) && theme === "default")) {
        return {
            modal: { width: { desktop: '450px', tablet: '300px', mobile: '300px' } }
        }
    }

    if ((layout === "floating-bottom" && theme === "form5") || (layout === "floating-bottom" && theme === "form3") || (layout === "floating-bottom" && theme === "form2") || (layout === "floating-bottom" && theme === "form2") || (layout === "floating-bottom" && theme === "form1") || (layout === "floating-bottom" && theme === "default")) {
        return {
            modal: { width: { desktop: '400px', tablet: '300px', mobile: '300px' } }
        }
    }

}

// Email Validation
export const ValidateEmail = (setEmailValid, setClassAddDisable, input) => {
    var validRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    if (input.match(validRegex)) {
        setEmailValid(true);
        setClassAddDisable(true);
    } else {
        setEmailValid(false);
        setClassAddDisable(false);
    }
}